import React from "react"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"
import PrivacyMobile from "../../../../components/body/pages/mobile/ng/legal/privacy"

const PrivacyPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/privacy-policy/"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <PrivacyMobile />
  </NoheaderFooter>
)

export default PrivacyPage
